<template>
  <div>
    <CRow>
      <CCol col="12">
        <CCard>
          <form id="CreateCity" @submit="CreateCity" >
          <CCardHeader>
            <CCol col="6" style="font-size: 25px;">إضافة مدينة</CCol>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <CInput label="الاسم" id="name" v-model="City.name" class="required" placeholder="ادخل اسم المدينة" required/>
              </CCol>
            </CRow>
            <div class="form-group">
              <label for="provinces">
                الأحجام
                <span class="star">*</span>
              </label>
              <multiselect v-model="selected" :value="selected" id="provinces" :options="provinces" :searchable="true" selectLabel="اضغط لإختيار هذا العنصر" :close-on-select="true"
                  openDirection = "bottom" selectedLabel=" العنصر المحدد" deselectLabel="اضغط لإلغاء اختيار هذا العنصر"
                  class=" required"  placeholder="اختر..." label="name" track-by="name" required> <span slot="noOptions">اللائحة فارغة</span> <span slot="noResult">لا توجد نتيجة</span>
              </multiselect>
              <!-- <select v-model="selected" id="provinces" class="form-control" required>
                <option v-for="option in provinces" v-bind:value="option.id">
                  {{ option.name }}
                </option>
              </select> -->
            </div>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" size="sm" color="primary"><CIcon name="cil-check-circle"/> حفظ</CButton>
              &emsp;
            <CButton type="reset" size="sm" color="danger" ><CIcon name="cil-ban"/>  مسح </CButton>
          </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import swal from 'sweetalert'
    import $ from 'jquery'
    export default {
        name: 'CreateCity',
        data: function() {
            return {
                City: {},
                image:'',
                provinces: [],
                selected: '',
            }
        },
        created() {
            let provinces = [];
            this.$http
            .get(`${process.env.VUE_APP_URL}provinces-list`)
            .then((response) => {
                console.log(response);
                $.each(response.data.data, function(key, value) {
                    provinces.push({id:value.id,name:value.name})
                });
                this.provinces = provinces;
            });
        },
        methods: {
            CreateCity: function(e) {
                e.preventDefault();
                let currentObj = this;
                let formData = new FormData();
                formData.append('name', this.City.name);
                if (this.selected.id)
                  formData.append('province_id', this.selected.id);
                this.$http.post(`${process.env.VUE_APP_URL}cities`, formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then( (response) => {
                        currentObj.output = response.data;
                        if(!response.data.error)
                        {
                            swal({ title:response.data.message, buttons: "تم"});
                        }
                        else
                        {
                            swal({ title:response.data.message, buttons: "تم"});
                        }

                    })
                    .catch(function (error) {
                        currentObj.output = error;
                    });
            },
        },
    }
</script>